import React from 'react';

import Layout from 'components/Layout';
import SEO from 'components/SEO';
import Hero from 'components/Hero';
import { FSPrivacyPolicy as PrivacyPolicy } from 'components/Pages/Legal/Sections';
import { TableResetStyles } from 'theme/global';

const FSPrivacyPolicy = () => (
  <>
    <Layout>
      <SEO
        title="Strike Financial Services Privacy Policy"
        description="Privacy Policy for Strike Financial Services Ltd."
        canonical="/legal/financial-services-privacy-policy"
      />
      <Hero
        title="Strike Financial Services Privacy Policy"
        subtitle="Last updated: Wednesday 26th July 2023"
      />
      {/* Reset to default HTML table styling */}
      <TableResetStyles />
      <PrivacyPolicy />
    </Layout>
  </>
);

export default FSPrivacyPolicy;
